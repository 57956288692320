
jQuery( document ).ready( function( $ ){

	$( '.cpc-deals-slider' ).slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	function adjustRegFormPosition(){
		var header_height = $( 'header' ).height();
		$( '.cpc-registration' ).css({
			'margin-top': '-' + header_height + 'px'
		});
		var card_top = header_height - 170;
		if( card_top > 0 ){
			$( '.cpc-card-number-form' ).css({
				'margin-top': card_top + 'px'
			});
		}
	}

	function showDealModal(){
		let src = $( this ).find( 'img' ).attr( 'data-modal-img' );
		$( '#offersModalOutput' ).attr( 'src', src );
		$( '#offersModal' ).modal( 'show' );
	}

	// Listeners & Calls
	window.addEventListener( 'resize', adjustRegFormPosition );
	adjustRegFormPosition();
	$( document ).on( 'click', '.cpc-deals-slider .slick-slide', showDealModal );

});